import { Box, useScrollTrigger } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { MobilePackagesHotelAvailabilityConnectorProps } from "./container";
import { AvailabilityNoResults } from "../AvailabilityNoResults";
import { getShowLaunchBanner } from "@capone/common";
import { ClientContext } from "../../../../App";
import { AvailabilityMap } from "../AvailabilityMap";
import {
  BackButton,
  BackToTopButton,
  FloatingMenuPill,
  IconName,
  LaunchBanner,
} from "halifax";
import { AvailabilityList } from "../AvailabilityList";
import clsx from "clsx";
import { PackagesAvailabilityCallState } from "../../reducer/state";
import { RewardsAccountSelection } from "../../../rewards/components";
import { AvailabilityFilter } from "../AvailabilityFilter";
import { MobileAvailabilityMapRollingGallery } from "../MobileAvailabilityMapRollingGallery";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/trackEvent";
import { MobileLocationSelection } from "../MobileLocationSelection";
import "./styles.scss";

export interface IMobilePackagesHotelAvailabilityProps
  extends MobilePackagesHotelAvailabilityConnectorProps,
    RouteComponentProps {
  onShowMap?: (open: boolean) => void;
}

export const MobilePackagesHotelAvailability = (
  props: IMobilePackagesHotelAvailabilityProps
) => {
  const {
    isFilteredHotelAvailabilityLodgingsEmpty,
    lodgings,
    lodgingIdInFocus,
    hotelAvailabilityCallState,
    appliedFilterCount,
    onShowMap,
  } = props;

  const [showMap, setShowMap] = React.useState(false);
  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);

  const { sessionInfo } = useContext(ClientContext);

  const showCorpLaunchBanner = getShowLaunchBanner(sessionInfo);

  const initialCallInProgress =
    hotelAvailabilityCallState ===
    PackagesAvailabilityCallState.InitialSearchCallInProcess;

  useEffect(() => {
    onShowMap?.(showMap);
    if (showMap) {
      // removes medallia from the fixed bottom that was covering pricing (only on the enlarged map)
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "none";
      }
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [showMap]);

  const renderMap = (isPreview: boolean, onClickPreview?: () => void) => {
    return <AvailabilityMap isPreview={isPreview} onClick={onClickPreview} />;
  };

  const renderFullHeightMap = () => (
    <Box className="mobile-lodging-availability-map-wrapper">
      <Box
        className={clsx("mobile-lodging-availability-map-container", {
          "full-height": lodgingIdInFocus === null,
        })}
      >
        {renderMap(false)}
        <Box className="map-back-button-container">
          <BackButton onClick={() => setShowMap(false)} />
        </Box>
        <Box className="map-filters-button-container">
          <AvailabilityFilter />
        </Box>
      </Box>
      <Box className="mobile-lodging-availability-map-bottom-items">
        <Box className="mobile-lodging-availability-map-rolling-gallery">
          <MobileAvailabilityMapRollingGallery />
        </Box>
      </Box>
    </Box>
  );

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const renderLocationSearch = () => {
    return (
      <Box
        className={clsx("mobile-location-search-contents global-mobile-nav", {
          scrolled: scrollTrigger,
        })}
      >
        <MobileLocationSelection />
      </Box>
    );
  };

  const renderRewardsAccount = () => {
    return (
      <Box className={"mobile-rewards-account-contents global-mobile-nav"}>
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
    );
  };

  const onClickPreview = () => {
    setShowMap(true);
    window.scrollTo(0, 0);
  };

  const renderHotelListView = () => (
    <Box className="mobile-lodging-availability-contents">
      {!!lodgings && lodgings.length > 0 && (
        <Box
          className={clsx(
            "mobile-availability-map-preview",
            "enhanced-hotel-filters"
          )}
        >
          {renderMap(true, onClickPreview)}
        </Box>
      )}
      {!isFilteredHotelAvailabilityLodgingsEmpty && !initialCallInProgress && (
        <Box className="mobile-availability-list-wrapper">
          <AvailabilityList />
        </Box>
      )}
      {!initialCallInProgress && (
        <FloatingMenuPill
          items={[
            {
              label: "Map",
              onClick: () => setShowMap(true),
              icon: IconName.MapOutline,
            },
            {
              label: "Sort & filter",
              onClick: () => setFilterModalOpen(true),
              icon: IconName.Settings,
              badge: appliedFilterCount
                ? String(appliedFilterCount)
                : undefined,
            },
          ]}
        />
      )}
    </Box>
  );

  return (
    <>
      <Box
        className={clsx("mobile-lodging-availability-page", config.TENANT, {
          "not-live": showCorpLaunchBanner,
        })}
      >
        {!showMap && (
          <>
            {renderLocationSearch()}
            <LaunchBanner
              isBusinessLive={!showCorpLaunchBanner}
              onClick={() => {
                trackEvent({
                  eventName: "complete_setup_clicked",
                  properties: { entry: "traveler_activation_banner" },
                });
              }}
            />
            {renderRewardsAccount()}
            {!isFilteredHotelAvailabilityLodgingsEmpty && renderHotelListView()}

            <BackToTopButton />
          </>
        )}
        {showMap && renderFullHeightMap()}
        {isFilteredHotelAvailabilityLodgingsEmpty && (
          <Box className="mobile-availability-no-results-wrapper">
            <AvailabilityNoResults />
          </Box>
        )}
        <AvailabilityFilter
          renderOpenModalButton={false}
          filterModalOpen={filterModalOpen}
          setFilterModalOpen={setFilterModalOpen}
        />
      </Box>
    </>
  );
};
