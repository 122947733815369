import dayjs from "dayjs";
import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "@reduxjs/toolkit";
import {
  IPriceRange,
  Lodging,
  LodgingCollectionEnum,
  SearchPackagesResponseInitial,
} from "redmond";
import { initialFilterState } from "../index";
import { isEqual } from "lodash-es";
import {
  PackagesAvailabilityCallState,
  PackagesHotelsAvailabilitySortOption,
} from "../state";
import {
  performAmenitiesFilter,
  performFreeCancelFilter,
  performHotelNameFilter,
  performLoyaltyProgramFilter,
  performMaxPriceFilter,
  performPolicyFilter,
  performStarRatingsFilter,
} from "../utils/processFilters";
import {
  orderByPriceLowToHigh,
  orderByStarRatingHighToLow,
} from "../utils/processSort";

export const getPackagesAvailabilityLodgings = (
  state: IStoreState
): Lodging[] =>
  state.packagesAvailability.availabilityResponse?.lodgingAvailability?.value
    .lodgings || [];
export const getPackagesByLodgingId = (state: IStoreState) =>
  state.packagesAvailability.availabilityResponse?.packagesByLodgingId;

export const getPackagesAvailabilityNextPageToken = (state: IStoreState) =>
  state.packagesAvailability.availabilityResponse?.nextPageToken;

export const getPackagesAvailabilityCallState = (state: IStoreState) =>
  state.packagesAvailability.packagesAvailabilityCallState;

export const getHotelAvailabilitySearchLocation = (state: IStoreState) =>
  state.packagesAvailability.searchLocation;

export const getHotelAvailabilityFromDate = (state: IStoreState) =>
  state.packagesAvailability.searchFromDate;

export const getHotelAvailabilityUntilDate = (state: IStoreState) =>
  state.packagesAvailability.searchUntilDate;

export const getPackageAvailabilityAdultsCount = (state: IStoreState) =>
  state.packagesAvailability.searchAdultsCount;

export const getPackageAvailabilityChildren = (state: IStoreState) =>
  state.packagesAvailability.searchChildren;

export const getPackageAvailabilityInfants = (state: IStoreState) =>
  state.packagesAvailability.searchInfants;

export const getPackagesAvailabilityRoomsCount = (state: IStoreState) =>
  state.packagesAvailability.searchRoomsCount;

export const getSearchedNightCount = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }
    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getMapBound = (state: IStoreState) =>
  state.packagesAvailability.mapBound;

export const getOpenDatesModal = (state: IStoreState) =>
  state.packagesAvailability.openDatesModal;

export const getViewHotelsNearLocation = (state: IStoreState) =>
  state.packagesAvailability.viewHotelsNearLocation;

export const getViewHotelsNearLocationCategories = (state: IStoreState) =>
  state.packagesAvailability.viewHotelsNearLocationCategories;

export const getHotelAvailabilityLodgingIdInFocus = (state: IStoreState) =>
  state.packagesAvailability.lodgingIdInFocus;

export const getHotelAvailabilityLodgingIdHovered = (state: IStoreState) =>
  state.packagesAvailability.lodgingIdHovered;

export const getPackageAvailabilitySearchDestinationLocationResult = (
  state: IStoreState
) => {
  return state.packagesAvailability.searchDestinationResult;
};

export const getPackageAvailabilitySearchLocation = (state: IStoreState) =>
  state.packagesAvailability.searchLocation;

export const getTravelersCount = createSelector(
  getPackageAvailabilityAdultsCount,
  getPackageAvailabilityChildren,
  getPackageAvailabilityInfants,
  (adultsCount, children, infants) => {
    return adultsCount + children?.length + infants?.length;
  }
);

export const getPackagesAvailabilityLodgingsSortOption = (state: IStoreState) =>
  state.packagesAvailability.sortOption;

export const getHotelAvailabilityStarRatingsFilter = (state: IStoreState) =>
  state.packagesAvailability.starRatings;

export const hasChangedHotelAvailabilityStarRatingsFilter = createSelector(
  getHotelAvailabilityStarRatingsFilter,
  (starRatings) => {
    return !isEqual(starRatings, initialFilterState.starRatings);
  }
);

export const getHotelAvailabilityAmenitiesFilter = (state: IStoreState) =>
  state.packagesAvailability.amenities;

export const hasChangedHotelAvailabilityAmenitiesFilter = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  (amenities) => {
    return !isEqual(amenities, initialFilterState.amenities);
  }
);
export const getHotelAvailabilityCurrency = createSelector(
  getPackagesAvailabilityLodgings,
  getPackagesByLodgingId,
  (lodgings, lodgingsById): string => {
    if (lodgings) {
      const lodgingWithPrice = lodgings.find(
        (lodging) =>
          lodgingsById?.[lodging.lodging?.id]?.packageDetails.pricing.totalPrice
      );

      if (lodgingWithPrice?.price) {
        return lodgingWithPrice.price.nightlyPrice.fiat.currencyCode;
      }
    }

    return "USD";
  }
);

export const getHotelAvailabilityMaxPriceFilter = (state: IStoreState) =>
  state.packagesAvailability.maxPrice;

export const getHotelAvailabilityMinMaxPriceRange = createSelector(
  getPackagesAvailabilityLodgings,
  getPackagesByLodgingId,
  (lodgings, lodgingsById): IPriceRange | null => {
    if (!lodgings || lodgings.length === 0) {
      return null;
    }
    let curMin =
      lodgingsById?.[lodgings[0].lodging.id]?.packageDetails.pricing.totalPrice
        .fiat.value;

    let curMax =
      lodgingsById?.[lodgings[0].lodging.id]?.packageDetails.pricing.totalPrice
        .fiat.value;

    lodgings.forEach((lodging) => {
      const lodgingPrice =
        lodgingsById?.[lodging.lodging.id]?.packageDetails.pricing.totalPrice
          .fiat.value;
      if (lodgingPrice) {
        if (!curMin || lodgingPrice < curMin) curMin = lodgingPrice;
        if (!curMax || lodgingPrice > curMax) curMax = lodgingPrice;
      }
    });

    // price happens to be optional, so there could be a case where all lodgings have no price
    if (curMin === undefined || curMax === undefined) return null;

    return {
      min: curMin,
      max: curMax,
    };
  }
);

export const hasChangedHotelAvailabilityMaxPriceFilter = createSelector(
  getHotelAvailabilityMaxPriceFilter,
  (maxPrice) => {
    return maxPrice !== initialFilterState.maxPrice;
  }
);

export const getHotelAvailabilityFreeCancelFilter = (state: IStoreState) =>
  state.packagesAvailability.freeCancel;

export const hasChangedHotelAvailabilityFreeCancelFilter = createSelector(
  getHotelAvailabilityFreeCancelFilter,
  (freeCancel) => freeCancel !== initialFilterState.freeCancel
);

export const getHotelAvailabilityHotelNameFilter = (state: IStoreState) =>
  state.packagesAvailability.hotelName;

export const hasChangedHotelAvailabilityHotelNameFilter = createSelector(
  getHotelAvailabilityHotelNameFilter,
  (hotelName) => {
    return !isEqual(hotelName, initialFilterState.hotelName);
  }
);

export const getHotelAvailabilityPolicyFilter = (state: IStoreState) =>
  state.packagesAvailability.isInPolicy;

export const hasChangedHotelAvailabilityPolicyFilter = createSelector(
  getHotelAvailabilityPolicyFilter,
  (isInPolicy) => isInPolicy !== initialFilterState.isInPolicy
);

export const getHotelAvailabilityLoyaltyProgramsFilter = (state: IStoreState) =>
  state.packagesAvailability.loyaltyPrograms;

export const getAppliedHotelAvailabilityFilterCount = createSelector(
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityFreeCancelFilter,
  hasChangedHotelAvailabilityHotelNameFilter,
  hasChangedHotelAvailabilityMaxPriceFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
  (
    hasChangedAmenitiesFilter,
    hasChangedFreeCancelFilter,
    hasChangedHotelNameFilter,
    hasChangedMaxPriceFilter,
    hasChangedStarRatingsFilter
  ) =>
    [
      hasChangedAmenitiesFilter,
      hasChangedFreeCancelFilter,
      hasChangedHotelNameFilter,
      hasChangedStarRatingsFilter,
      hasChangedMaxPriceFilter,
    ].filter((applied) => applied).length
);

export const getDeduplicatedLodgings = createSelector(
  getPackagesAvailabilityLodgings,
  (lodgings) => {
    let filteredLodgings = [];
    if (lodgings?.length) {
      // filter out premier collection versions
      filteredLodgings = lodgings.filter(
        (lodging) => lodging.lodgingCollection !== LodgingCollectionEnum.Premier
      );

      return filteredLodgings;
    } else {
      return [];
    }
  }
);

const getAllHotelAvailabilityFilters = createSelector(
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityStarRatingsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  (
    amenities,
    starRatings,
    maxPrice,
    hotelName,
    freeCancel,
    isInPolicy,
    loyaltyPrograms
  ) => {
    return {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      isInPolicy,
      loyaltyPrograms,
    };
  }
);

export const getFilteredHotelAvailabilityLodgings = createSelector(
  getAllHotelAvailabilityFilters,
  getDeduplicatedLodgings,
  getPackagesByLodgingId,
  (filters, lodgings, lodgingsById): Lodging[] => {
    const {
      amenities,
      starRatings,
      maxPrice,
      hotelName,
      freeCancel,
      isInPolicy,
      loyaltyPrograms,
    } = filters;

    const meetsFilterPredicates = (lodging: Lodging) => {
      if (
        !performAmenitiesFilter(lodging, amenities) ||
        !performStarRatingsFilter(lodging, starRatings) ||
        (lodgingsById?.[lodging.lodging.id] &&
          !performMaxPriceFilter(
            lodging,
            lodgingsById?.[lodging.lodging.id],
            maxPrice
          )) ||
        !performHotelNameFilter(lodging, hotelName) ||
        !performPolicyFilter(lodging, isInPolicy) ||
        !performFreeCancelFilter(lodging, freeCancel) ||
        !performLoyaltyProgramFilter(lodging, loyaltyPrograms)
      ) {
        return false;
      }

      return true;
    };
    return (lodgings ?? []).filter(meetsFilterPredicates);
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getPackagesAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings.length === 0 &&
      availabilityCallState === PackagesAvailabilityCallState.Complete
    );
  }
);

export const getFilteredAndSortedHotelAvailabilityLodgings = createSelector(
  getFilteredHotelAvailabilityLodgings,
  getPackagesAvailabilityLodgingsSortOption,
  getPackagesByLodgingId,
  (lodgings, sortOption, lodgingsById): Lodging[] => {
    const { availableLodgings, unavailableLodgings } = lodgings.reduce(
      (prev, curr) => {
        const available =
          typeof curr.available === "undefined" ? true : curr.available;

        if (available) prev.availableLodgings.push(curr);
        else prev.unavailableLodgings.push(curr);

        return prev;
      },
      {
        availableLodgings: [] as Lodging[],
        unavailableLodgings: [] as Lodging[],
      }
    );

    const sortedAvailableHotels = (() => {
      switch (sortOption) {
        case PackagesHotelsAvailabilitySortOption.Pricing:
          return orderByPriceLowToHigh(availableLodgings, lodgingsById ?? {});
        case PackagesHotelsAvailabilitySortOption.StarRating:
          return orderByStarRatingHighToLow(availableLodgings);
        case PackagesHotelsAvailabilitySortOption.Recommended:
          return availableLodgings;
      }
    })();

    return sortedAvailableHotels.concat(unavailableLodgings);
  }
);

export const getIsUnfilteredHotelAvailabilityLodgingsEmpty = createSelector(
  getDeduplicatedLodgings,
  getPackagesAvailabilityCallState,
  (lodgings, availabilityCallState): boolean => {
    return (
      lodgings?.length === 0 &&
      availabilityCallState === PackagesAvailabilityCallState.Complete
    );
  }
);

export const getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching =
  createSelector(
    getFilteredHotelAvailabilityLodgings,
    getPackagesAvailabilityCallState,
    (lodgings, availabilityCallState): boolean => {
      return (
        lodgings.length === 0 &&
        (availabilityCallState === PackagesAvailabilityCallState.Complete ||
          availabilityCallState ===
            PackagesAvailabilityCallState.FollowUpSearchCallSuccess)
      );
    }
  );

export const getRecommendedFlights = (state: IStoreState) =>
  (
    state.packagesAvailability
      .availabilityResponse as SearchPackagesResponseInitial
  )?.tripDetailsResponse;
