import { IStoreState } from "../../../../reducers/types";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MobileEditLocationsModal } from "./component";
import {
  getAdultsCount,
  getChildrenCount,
  getDepartureDate,
  getDestination,
  getInfantsCount,
  getOrigin,
  getReturnDate,
  getStopsOption,
  getTravelers,
} from "../../../search/reducer";
import {
  setDestination,
  setOrigin,
  setTravelers,
} from "../../../search/actions/actions";
import {
  resetPackagesAvailabilityCallState,
  setOpenDatesModal,
} from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    destination: getDestination(state),
    travelers: getTravelers(state),
    adultsCount: getAdultsCount(state),
    children: getChildrenCount(state),
    infants: getInfantsCount(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    stopsOptions: getStopsOption(state),
  };
};

const mapDispatchToProps = {
  setOrigin,
  setDestination,
  setOpenDatesModal,
  resetPackagesAvailabilityCallState,
  setTravelers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileEditLocationModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileEditLocationsModal = connector(
  withRouter(MobileEditLocationsModal)
);
