import dayjs from "dayjs";

const OUTBOUND = "Outbound";
const RETURN = "Return";
// const FLIGHT = "Flight";

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  date: Date | string
) => ({
  type: isDeparture ? OUTBOUND : RETURN,
  description: ` to ${location} on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const RECOMMENDED_FLIGHTS = "Recommended flights";
export const INFO_HEADER = "We recommend this flight for your package";
export const HOW_IT_WORKS = "How it works";
export const HOW_IT_WORKS_INFO_POINTS = [
  "In order to show you a total package price, this recommendedflight is included in all hotel results.",
  "You can select this recommended flight, or select any other available flight after you select a hotel.",
  "Total prices will vary based on your final flight selection.",
];

export const CHOOSE_DIFF_FLIGHTS =
  "You can choose a different flight after you choose a hotel. ";
export const LEARN_MORE = "Learn more";
