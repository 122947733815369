import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { RecommendedFlightsCardConnectorProps } from "./container";
import dayjs from "dayjs";
import {
  removeTimezone,
  AirlineIcon,
  formatInterval,
  Icon,
  IconName,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
  MobilePopoverCard,
  ButtonWrap,
} from "halifax";
import { TripDetails, TripSlice } from "redmond";
import { uniqBy } from "lodash-es";
import * as textConstants from "./textConstants";
import "./styles.scss";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";

export interface IRecommendedFlightsCardProps
  extends RecommendedFlightsCardConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}
export const RecommendedFlightsCard = (props: IRecommendedFlightsCardProps) => {
  const { recommendedFlights, isMobile } = props;
  const [openRecommendedFlightsModal, setOpenRecommendedFlightsModal] =
    useState(false);

  if (!recommendedFlights) return null;

  const renderFlightCard = (departure: boolean, tripDetails: TripDetails) => {
    const getSliceIndex = (
      departure: boolean,
      details: TripDetails
    ): number => {
      return details.slices.findIndex((slice: TripSlice) =>
        departure ? slice.outgoing : !slice.outgoing
      );
    };

    const sliceIndex = getSliceIndex(departure, tripDetails);
    const tripSlice = tripDetails.slices[sliceIndex];

    const airlines: any[] = tripSlice.segmentDetails.map((sd) => ({
      ...sd.operatingAirline,
      tooltip: `1 or more segments operated by ${sd.operatingAirline.name}`,
    }));

    // put marketing airline as first without tooltip
    airlines.unshift(tripSlice.segmentDetails[0].marketingAirline);

    // remove duplicates
    const uniqSegmentAirlines = uniqBy(airlines, "code");

    const { type, description } = textConstants.getReviewCardHeaderWithType(
      departure,
      tripSlice.destinationName,
      removeTimezone(tripSlice.departureTime)
    );

    return (
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <span className="bold">{type}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></span>
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Typography variant="body1" className="flight-timespan">
              {dayjs(removeTimezone(tripSlice.departureTime)).format("h:mm A")}{" "}
              <span className="flight-time-icon">
                <Icon name={IconName.B2BAirplaneIcon} />
                <Box className="flight-line" />
                <Box className="flight-line-circle" />
              </span>
              {dayjs(removeTimezone(tripSlice.arrivalTime)).format("h:mm A")}
            </Typography>
            <Box className="card-airline-container">
              {uniqSegmentAirlines.map((airline, index) => (
                <>
                  {/* Only operating airlines should have a tooltip */}
                  <Tooltip
                    arrow
                    title={airline.tooltip}
                    key={`airline-tooltip-${index}-${airline.name}`}
                  >
                    <span>
                      <AirlineIcon airlineCode={airline.code} />
                    </span>
                  </Tooltip>
                  {/* Only first airline (marketing) should show name */}
                  {index === 0 && (
                    <Typography
                      variant="body2"
                      key={`airline-name-${index}-${airline.name}`}
                    >
                      {airline.name}
                    </Typography>
                  )}
                </>
              ))}
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1" className="flight-timespan">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {textConstants.getStopsString(tripSlice.stops)}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const getRecommendedFlightsInfo = () => {
    return (
      <Box
        className={clsx("recommended-flights-info-cards", { mobile: isMobile })}
      >
        {renderFlightCard(true, recommendedFlights.value)}
        {renderFlightCard(false, recommendedFlights.value)}
      </Box>
    );
  };
  const getPopoverContent = () => {
    return (
      <Box
        className={clsx("recommended-flights-details-modal-content", {
          mobile: isMobile,
        })}
      >
        <Typography className="info-header">
          {textConstants.INFO_HEADER}
        </Typography>
        {getRecommendedFlightsInfo()}
        <Typography className="how-it-works-header">
          {textConstants.HOW_IT_WORKS}
        </Typography>
        <Typography className="how-it-works-list">
          <ul>
            {textConstants.HOW_IT_WORKS_INFO_POINTS.map((info) => {
              return <li>{info}</li>;
            })}
          </ul>
        </Typography>
      </Box>
    );
  };
  const renderPopover = () => {
    if (isMobile) {
      return (
        <MobilePopoverCard
          open={openRecommendedFlightsModal}
          onClose={() => setOpenRecommendedFlightsModal(false)}
          centered
          contentClassName="recommended-flights-detail-popover-content"
          headerElement={
            <Box className="recommended-flights-details-modal-header">
              <Typography className="header-title">
                {textConstants.RECOMMENDED_FLIGHTS}
              </Typography>
              <ActionLink
                className="modal-close-button"
                content={<CloseButtonIcon />}
                label="Close"
                onClick={() => setOpenRecommendedFlightsModal(false)}
              />
            </Box>
          }
          className="recommended-flights-detail-modal mobile"
        >
          {getPopoverContent()}
        </MobilePopoverCard>
      );
    }
    return (
      <DesktopPopupModal
        className="recommended-flights-detail-modal"
        open={openRecommendedFlightsModal}
        onClose={() => setOpenRecommendedFlightsModal(false)}
        invisibleBackdrop={false}
      >
        {getPopoverContent()}
      </DesktopPopupModal>
    );
  };
  return (
    <Box className="recommended-flights-root">
      <Box className="recommended-flights-info-header">
        <Typography className="header-text">
          {textConstants.CHOOSE_DIFF_FLIGHTS}
          <ButtonWrap
            onClick={(e) => {
              e.stopPropagation();
              setOpenRecommendedFlightsModal(true);
            }}
            className="learn-more-cta"
          >
            {textConstants.LEARN_MORE}
          </ButtonWrap>
        </Typography>
      </Box>
      {getRecommendedFlightsInfo()}
      {renderPopover()}
    </Box>
  );
};
