import { ConnectedProps, connect } from "react-redux";
// import { IStoreState } from "../../reducers/types";
import { withRouter } from "react-router";
import { PackagesHotelsAvailability } from "./component";
import { fetchTravelWalletDetails } from "../travel-wallet/actions/actions";
import { fetchInitialPackagesAvailability } from "./actions/actions";
import { IStoreState } from "../../reducers/types";
import { getPackagesAvailabilityCallState } from "./reducer";
import { getRewardsAccountWithLargestEarnForBanner } from "../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    packagesAvailabilityCallState: getPackagesAvailabilityCallState(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

const mapDispatchToProps = {
  fetchTravelWalletDetails,
  fetchInitialPackagesAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesHotelsAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesHotelsAvailability = withRouter(
  connector(PackagesHotelsAvailability)
);
