import { Box } from "@material-ui/core";
import clsx from "clsx";
import { Icon, IconName, MobileHotelMapTooltip } from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Lodging } from "redmond";
import { config } from "../../../../api/config";

import { MobileAvailabilityMapRollingGalleryConnectorProps } from "./container";
import "./styles.scss";

export interface IMobileAvailabilityMapRollingGalleryProps
  extends MobileAvailabilityMapRollingGalleryConnectorProps,
    RouteComponentProps {}

export const MobileAvailabilityMapRollingGallery = (
  props: IMobileAvailabilityMapRollingGalleryProps
) => {
  const {
    lodgings,
    lodgingIdInFocus,
    nightCount,
    selectedAccountId,

    isVentureX,
    setLodgingIdInFocus,
    // history,
    travelersCount,
    packagesByLodgingId,
  } = props;
  const lodgingInFocus = React.useMemo(
    () =>
      lodgings?.find(
        (lodging: Lodging) => lodging.lodging.id === lodgingIdInFocus
      ),
    [lodgingIdInFocus, lodgings]
  );

  const renderEachLodging = (lodging?: Lodging) => {
    if (!lodging) return null;
    // const lodgingData = lodging.lodging;

    const openShopPage = () => {
      // [Packages TO-DO]: Update to shop params when building shop
      //   let params;
      //   params = transformToStringifiedQuery({
      //     lodgingId: lodgingData.id,
      //     lodgingSelection: mapBounds
      //       ? {
      //           LodgingSelection: LodgingSelectionEnum.Location,
      //           descriptor: mapBounds,
      //         }
      //       : (searchLocation?.id as IIdLodgings).lodgingSelection,
      //     ...hotelQueryParams,
      //     roomsCount,
      //   });
      //   history.push(`${PATH_SHOP}${params}`, { fromPage: location.pathname });
    };

    return (
      <Box
        className={clsx(
          "mobile-lodging-availability-map-rolling-gallery-wrapper"
        )}
      >
        <span
          className={clsx("mobile-hotel-tooltip-close-button", config.TENANT)}
          onClick={(e) => {
            e.stopPropagation();
            setLodgingIdInFocus(null);
          }}
        >
          <Icon name={IconName.CloseXCircle} />
        </span>
        <MobileHotelMapTooltip
          lodging={lodging}
          onClick={openShopPage}
          nightCount={nightCount || 0}
          rewardsKey={selectedAccountId ?? ""}
          isVentureX={isVentureX}
          roomsCount={1}
          variant="package"
          travelersCount={travelersCount}
          packagePricing={
            packagesByLodgingId?.[lodging.lodging.id]?.packageDetails.pricing
          }
        />
      </Box>
    );
  };

  return renderEachLodging(lodgingInFocus);
};
