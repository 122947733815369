import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { MobileLocationSelection } from "./component";

import { IStoreState } from "../../../../reducers/types";
import {
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setLoyaltyProgramsFilter,
  setMaxPriceFilter,
  setOpenDatesModal,
  setPackagesHotelsAvailabilitySortOption,
  setPolicyFilter,
  setStarRatingsFilter,
} from "../../actions/actions";
import {
  getDepartureDate,
  getDestination,
  getReturnDate,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    destination: getDestination(state),
  };
};

const mapDispatchToProps = {
  setStarRatingsFilter,
  setAmenitiesFilter,
  setMaxPriceFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setPackagesHotelsAvailabilitySortOption,
  setPolicyFilter,
  setLoyaltyProgramsFilter,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSelection = withRouter(
  connector(MobileLocationSelection)
);
