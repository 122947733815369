import React from "react";
import axios from "axios";
import { getUserDeviceData } from "halifax";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  B2B_PORTAL_JWT_SECRET_KEY,
  B2B_PORTAL_CSRF_TOKEN,
  B2B_PORTAL_AUTH_REDIRECT_TO,
  B2B_PORTAL_UNAUTHORIZED_PATH,
  NETWORK_CALL_FAILED,
} from "redmond";

import { analyticsEventApi } from "../../api/v0/paths";
import {
  addTrackingProperties,
  useExperiments,
} from "../../context/experiments";
import {
  USER_SOURCE_KEY,
  USER_MEDIUM_KEY,
  useUtmParams,
} from "../../context/userSource";
import { getRewardsUserProperties } from "../../modules/rewards/reducer";
import { trackEvent } from "../../api/v0/analytics/trackEvent";

interface IAxiosInterceptors {
  children?: React.ReactNode;
  isAgentPortal: boolean;
}

const defaultProps: Partial<IAxiosInterceptors> = {
  isAgentPortal: false,
};

const AxiosInterceptors = (props: IAxiosInterceptors) => {
  const { children, isAgentPortal } = props;
  const expState = useExperiments();
  const history = useHistory();
  const location = useLocation();

  const utmParams = useUtmParams();

  const getCookie = (name: string) => {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
    else return undefined;
  };

  const getCsrfToken = () => getCookie(B2B_PORTAL_CSRF_TOKEN);

  const setAuthRedirectUrl = (url: string) =>
    sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, url);

  const selectedRewardsEventInfo = useSelector(getRewardsUserProperties);

  React.useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (res) => res,
      (error) => {
        if (
          error?.request?.responseURL &&
          !error?.request?.responseURL.includes("event") &&
          !error?.request?.responseURL.includes("userInfo")
        ) {
          trackEvent({
            eventName: NETWORK_CALL_FAILED,
            properties: {
              url: window.location.pathname,
              endpoint: error?.request?.responseURL,
              failure_code: error?.request?.status,
              failure_reason: error?.request?.statusText,
              failure_message: error?.request?.responseText,
            },
          });
        }
        if (error.response?.status === 401) {
          setAuthRedirectUrl(`${location.pathname}${location.search}`);
          history.push(B2B_PORTAL_UNAUTHORIZED_PATH);
        }
        return Promise.reject(error);
      }
    );

    const requestInterceptor = axios.interceptors.request.use(
      (req) => {
        const csrfToken = getCsrfToken();

        //TODO: remove GCP token
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            B2B_PORTAL_JWT_SECRET_KEY
          )}`,
          ...(csrfToken && { "H-Csrf-Token": csrfToken }),
        };

        if (req.url === analyticsEventApi) {
          let properties = {
            ...selectedRewardsEventInfo,
            ...req.data.properties,
            ...getUserDeviceData(),
            is_agent_session: isAgentPortal,
          };

          if (expState?.trackingProperties) {
            properties = addTrackingProperties(
              expState.trackingProperties,
              properties
            );
          }

          req.data.properties = properties;
          if (utmParams) {
            const { userSource, userMedium } = utmParams;

            req.data.properties[USER_SOURCE_KEY] = userSource;
            req.data.properties[USER_MEDIUM_KEY] = userMedium;
          }
        }

        req.headers.set({ ...req.headers, ...headers });
        return req;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    //runs on component unmount
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAgentPortal, expState.trackingProperties]);

  return <>{children}</>;
};

AxiosInterceptors.defaultProps = defaultProps;

export default AxiosInterceptors;
