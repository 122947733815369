import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";

import { AvailabilityMapPricePin } from "./component";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../../rewards/reducer";
import { getPackagesByLodgingId, getTravelersCount } from "../../../../reducer";
const mapStateToProps = (state: IStoreState) => {
  return {
    accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    packagesByLodgingId: getPackagesByLodgingId(state),
    travelersCount: getTravelersCount(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedAvailabilityMapPricePinProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityMapPricePin = connector(
  AvailabilityMapPricePin
);
