import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../reducers/types";
import { withRouter } from "react-router";
import { PackagesSearch } from "./component";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccountWithLargestValue,
} from "../rewards/reducer";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import { resetPackagesAvailabilityCallState } from "../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  largestValueAccount: getRewardsAccountWithLargestValue(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
});

const mapDispatchToProps = {
  fetchRewardsAccounts,
  resetPackagesAvailabilityCallState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPackagesSearch = withRouter(connector(PackagesSearch));
