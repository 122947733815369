import { combineReducers } from "@reduxjs/toolkit";
import { reducer as packagesRewards } from "../modules/rewards/reducer";
import { reducer as packagesSearch } from "../modules/search/reducer";
import { reducer as packagesAvailability } from "../modules/availability/reducer";

export const rootReducer = combineReducers({
  packagesRewards,
  packagesSearch,
  packagesAvailability,
});
