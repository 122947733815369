import {
  ActionButton,
  AmenitiesSelection,
  FreeCancelFilter,
  GenericDropdown,
  getCurrencySymbol,
  HotelAvailabilityFilter,
  Icon,
  IconName,
  LoyaltyProgramsSelection,
  PolicyStatusSelection,
  StarRatingsSelection,
  useDeviceTypes,
} from "halifax";
import React from "react";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";
import { AvailabilityFilterConnectorProps } from "./container";
import { useFilterLabelValues } from "./useFilterLabelValues";
import { initialFilterState, initialState } from "../../reducer";
import { Box } from "@material-ui/core";

import { config } from "../../../../api/config";
import { debounce, isEqual } from "lodash-es";
import { PackagesHotelsAvailabilitySortOption } from "../../reducer/state";
import { sortOptions } from "../AvailabilitySort";
import { isCorpTenant } from "@capone/common";

export interface IAvailabilityFilterProps
  extends AvailabilityFilterConnectorProps,
    RouteComponentProps {
  renderOpenModalButton?: boolean;
  filterModalOpen?: boolean;
  setFilterModalOpen?: (open: boolean) => void;
}

export const AvailabilityFilter = (props: IAvailabilityFilterProps) => {
  const {
    starRatings,
    hasChangedStarRatingsFilter,
    setStarRatingsFilter,
    amenities,
    hasChangedAmenitiesFilter,
    setAmenitiesFilter,
    currency,
    maxPrice,
    minMaxPriceRange,
    setMaxPriceFilter,
    hasFreeCancelFilter,
    setFreeCancelFilter,
    hotelName,
    setHotelNameFilter,
    sortOption,
    setPackagesHotelsAvailabilitySortOption,
    loyaltyPrograms,
    setLoyaltyProgramsFilter,
    isInPolicy,
    hasChangedPolicyFilter,
    setPolicyFilter,
    appliedFilterCount,
    renderOpenModalButton = false,
    filterModalOpen = false,
    setFilterModalOpen,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [localMaxPriceFilter, setLocalMaxPriceFilter] =
    React.useState<number>(maxPrice);

  React.useEffect(() => {
    setLocalMaxPriceFilter(maxPrice);
  }, [maxPrice]);

  const isCorporateTenant = isCorpTenant(config.TENANT);
  // TODO(corp): temporarily hide hotel loyalty filter
  const showLoyaltyFilter = false;

  React.useEffect(() => {
    setLocalMaxPriceFilter(maxPrice);
  }, [maxPrice]);

  const setMaxPriceFilterHandler = (maxPrice: number) => {
    setMaxPriceFilter(maxPrice);
  };

  const setMaxPriceFilterDebounced = React.useMemo(
    () => debounce(setMaxPriceFilterHandler, 150),
    []
  );

  const readyToReset =
    !isEqual(amenities, initialFilterState.amenities) ||
    !isEqual(starRatings, initialFilterState.starRatings) ||
    !isEqual(isInPolicy, initialFilterState.isInPolicy) ||
    !isEqual(loyaltyPrograms, initialFilterState.loyaltyPrograms) ||
    maxPrice !== initialFilterState.maxPrice ||
    !isEqual(hotelName, initialFilterState.hotelName);

  const { ratingLabel, amenitiesLabel } = useFilterLabelValues({
    starRatings,
    amenities,
  });

  const renderDropdownButtons = () => {
    return (
      <>
        <GenericDropdown
          buttonClassName={clsx(
            "hotel-availability-dropdown",
            "star-ratings",
            "b2b-shop-filter",
            "enhanced-hotel-filters",
            {
              "has-value": hasChangedStarRatingsFilter,
            }
          )}
          popoverClassName={clsx(
            "hotel-availability-star-ratings-dropdown-popover",
            "b2b",
            "enhanced-hotel-filters"
          )}
          ariaLabel={`${textConstants.RATING_TEXT} filter`}
          dropdownLabel={
            <>
              <strong>
                {textConstants.RATING_TEXT}
                {hasChangedStarRatingsFilter ? ": " : ""}
              </strong>
              {hasChangedStarRatingsFilter ? ratingLabel : ""}
            </>
          }
          dropdownIcon={
            hasChangedStarRatingsFilter ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setStarRatingsFilter([]);
                }}
              >
                <Icon name={IconName.XCircle} />
              </div>
            ) : undefined
          }
          dropdownContent={
            <StarRatingsSelection
              className="desktop-hotel-availability-star-ratings"
              starRatings={starRatings}
              setStarRatings={(starRatings) =>
                setStarRatingsFilter(starRatings)
              }
            />
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />
        <GenericDropdown
          buttonClassName={clsx(
            "hotel-availability-dropdown",
            "amenities",
            "b2b-shop-filter",
            "enhanced-hotel-filters",
            {
              "has-value": hasChangedAmenitiesFilter,
            }
          )}
          popoverClassName={clsx(
            "hotel-availability-amenities-dropdown-popover",
            "b2b",
            "enhanced-hotel-filters"
          )}
          ariaLabel={`${textConstants.AMENITIES_FILTER_LABEL} filter`}
          dropdownLabel={
            <>
              <strong>
                {textConstants.AMENITIES_FILTER_LABEL}
                {hasChangedAmenitiesFilter ? ": " : ""}
              </strong>
              {hasChangedAmenitiesFilter ? amenitiesLabel : ""}
            </>
          }
          dropdownIcon={
            hasChangedAmenitiesFilter ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setAmenitiesFilter([]);
                }}
              >
                <Icon name={IconName.XCircle} />
              </div>
            ) : undefined
          }
          dropdownContent={
            <AmenitiesSelection
              amenities={amenities}
              setAmenities={setAmenitiesFilter}
              useTopAmenities
            />
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />

        <GenericDropdown
          buttonClassName={clsx(
            "hotel-availability-dropdown",
            "free-cancel",
            "b2b-shop-filter",
            "enhanced-hotel-filters",
            {
              "has-value": hasFreeCancelFilter,
            }
          )}
          popoverClassName={clsx(
            "hotel-availability-price-dropdown-popover",
            "b2b",
            "enhanced-hotel-filters"
          )}
          ariaLabel={`${textConstants.CANCELLATION} filter`}
          dropdownLabel={
            <>
              <strong>
                {textConstants.CANCELLATION}
                {hasFreeCancelFilter ? ":" : ""}
              </strong>
              {hasFreeCancelFilter ? ` Free` : ""}
            </>
          }
          dropdownIcon={
            hasFreeCancelFilter ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setFreeCancelFilter(false);
                }}
              >
                <Icon name={IconName.XCircle} />
              </div>
            ) : undefined
          }
          dropdownContent={
            <>
              <FreeCancelFilter
                boldText
                hasFreeCancelFilter={hasFreeCancelFilter}
                setFreeCancelFilter={setFreeCancelFilter}
              />
            </>
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />

        {hotelName && (
          <Box className={clsx("hotel-filter-pill", "hotel-name")}>
            <Box className="filter-label-wrapper">
              <span className="filter-label-text">
                {textConstants.HOTEL_NAME_FILTER_LABEL(hotelName)}
              </span>
            </Box>
            <button onClick={() => setHotelNameFilter("")}>
              <Icon name={IconName.XCircle} />
            </button>
          </Box>
        )}
        {isCorporateTenant && (
          <>
            {showLoyaltyFilter && (
              <GenericDropdown
                buttonClassName={clsx(
                  "hotel-availability-dropdown",
                  "b2b-shop-filter",
                  "enhanced-hotel-filters"
                )}
                popoverClassName={clsx(
                  "hotel-availability-loyalty-programs-dropdown-popover",
                  "b2b"
                )}
                ariaLabel="Hotel loyalty program filter"
                dropdownLabel={
                  <strong>{textConstants.HOTELS_LOYALTY_PROGRAMS}</strong>
                }
                dropdownContent={
                  <LoyaltyProgramsSelection
                    className="desktop-hotel-availability-loyalty-programs"
                    loyaltyPrograms={loyaltyPrograms}
                    setLoyaltyPrograms={(loyaltyPrograms: string[]) =>
                      setLoyaltyProgramsFilter(loyaltyPrograms)
                    }
                  />
                }
              />
            )}
            <GenericDropdown
              buttonClassName={clsx(
                "hotel-availability-dropdown",
                "b2b-shop-filter",
                "enhanced-hotel-filters",
                {
                  "has-value": hasChangedPolicyFilter,
                }
              )}
              popoverClassName={clsx(
                "hotel-availability-policy-dropdown-popover",
                "b2b"
              )}
              ariaLabel="Policy status filter"
              dropdownLabel={
                <strong>{textConstants.HOTELS_POLICY_STATUS}</strong>
              }
              dropdownContent={
                <PolicyStatusSelection
                  isInPolicy={isInPolicy}
                  setIsInPolicy={setPolicyFilter}
                />
              }
              dropdownIcon={
                hasChangedPolicyFilter ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setPolicyFilter(false);
                    }}
                  >
                    <Icon name={IconName.XCircle} />
                  </div>
                ) : undefined
              }
            />
          </>
        )}
      </>
    );
  };
  return (
    <>
      {renderOpenModalButton && (
        <ActionButton
          className={clsx(
            "hotel-availability-filter-action-button",
            "enhanced-hotel-filters",
            {
              mobile: matchesMobile,
            }
          )}
          defaultStyle="h4r-secondary"
          message={textConstants.SORT_DROPDOWN_LABEL_EXPERIMENT(
            appliedFilterCount
          )}
          onClick={() => setOpenModal(true)}
          icon={<Icon name={IconName.Settings} />}
        />
      )}
      {matchesDesktop && renderDropdownButtons()}
      <HotelAvailabilityFilter
        openModal={filterModalOpen ?? openModal}
        onClose={() => {
          setFilterModalOpen ? setFilterModalOpen(false) : setOpenModal(false);
        }}
        isInPolicy={isInPolicy}
        setIsInPolicy={setPolicyFilter}
        isMobile={matchesMobile}
        amenities={amenities}
        setAmenities={setAmenitiesFilter}
        starRatings={starRatings}
        setStarRatings={setStarRatingsFilter}
        hotelPriceRange={minMaxPriceRange || { min: 0, max: 0 }}
        setHotelMaxPrice={setMaxPriceFilterDebounced}
        hotelMaxPrice={localMaxPriceFilter}
        currencySymbol={getCurrencySymbol(currency)}
        hotelName={hotelName}
        setHotelName={setHotelNameFilter}
        readyToReset={readyToReset}
        sortOptions={sortOptions.map((value) => ({
          value,
          label: textConstants.sortOptionLabel[value],
        }))}
        selectedSortOption={sortOption}
        setSelectedSortOption={(value) =>
          setPackagesHotelsAvailabilitySortOption(
            value as PackagesHotelsAvailabilitySortOption
          )
        }
        isFreeCancelExperiment
        setFreeCancelFilter={setFreeCancelFilter}
        hasFreeCancelFilter={hasFreeCancelFilter}
        setHotelsOnSaleFilter={() => {}}
        hasHotelsOnSaleFilter={false}
        defaultSortOption={initialState.sortOption}
        titles={textConstants.FILTER_MODAL_TITLES}
        loyaltyPrograms={loyaltyPrograms}
        setLoyaltyPrograms={setLoyaltyProgramsFilter}
        tenant={config.TENANT}
        showHotelsOnSaleFilter={false}
      />
    </>
  );
};
