import {
  Lodging,
  AmenityEnum,
  HotelStarRatingEnum,
  CorpLodging,
  PackagedLodging,
} from "redmond";
import { isEqual, intersection } from "lodash-es";
import { mapAmenitiesToTopAmenities } from "halifax";
import { initialFilterState } from "..";

export const performAmenitiesFilter = (
  lodging: Lodging,
  amenitiesFilter: AmenityEnum[]
): boolean => {
  if (amenitiesFilter.length > 0) {
    const amenitiesList = (lodging.lodging.amenities ?? []).map(
      (status) => status.amenity
    );

    const amenitiesFilterMappedToTopAmenities = mapAmenitiesToTopAmenities(
      amenitiesFilter
    ).map((topAmenity) => topAmenity.label);

    const lodgingAmenitiesMappedToTopAmenities = mapAmenitiesToTopAmenities(
      amenitiesList
    ).map((topAmenity) => topAmenity.label);

    if (
      !isEqual(
        intersection(
          amenitiesFilterMappedToTopAmenities,
          lodgingAmenitiesMappedToTopAmenities
        ),
        amenitiesFilterMappedToTopAmenities
      )
    )
      return false;
  }

  return true;
};

export const performStarRatingsFilter = (
  lodging: Lodging,
  starRatingsFilter: HotelStarRatingEnum[]
): boolean => {
  if (
    starRatingsFilter.length > 0 &&
    !starRatingsFilter.includes(lodging.lodging.starRating)
  ) {
    return false;
  }

  return true;
};

export const performMaxPriceFilter = (
  lodging: Lodging,
  lodgingById: PackagedLodging,
  maxPrice: number
): boolean => {
  if (
    maxPrice !== initialFilterState.maxPrice &&
    lodging.available &&
    (!lodgingById.packageDetails.pricing ||
      lodgingById.packageDetails.pricing.totalPrice.fiat.value > maxPrice)
  ) {
    return false;
  }

  return true;
};

export const performHotelNameFilter = (
  lodging: Lodging,
  hotelName: string
): boolean => {
  if (!lodging.lodging.name.toLowerCase().includes(hotelName.toLowerCase())) {
    return false;
  }

  return true;
};

export const performFreeCancelFilter = (
  lodging: Lodging,
  freeCancel: boolean
) => {
  if (lodging.isFreeCancel === undefined) {
    console.log(lodging);
  }
  return freeCancel ? lodging.isFreeCancel : true;
};

export const performPolicyFilter = (
  lodging: Lodging | CorpLodging,
  isInPolicyEnabled: boolean
): boolean => {
  const corpLodging = (lodging as CorpLodging).lodging.corporateTravel;
  if (isInPolicyEnabled && corpLodging) {
    return corpLodging.policyCompliance.isInPolicy;
  }

  return true;
};

export const performLoyaltyProgramFilter = (
  lodging: Lodging,
  selectedLoyaltyPrograms: string[]
): boolean => {
  if (selectedLoyaltyPrograms.length === 0) {
    return true;
  }

  const isLoyaltyProgramSelected = selectedLoyaltyPrograms.some(
    (selectedLoyaltyProgram) => {
      return selectedLoyaltyProgram === lodging.loyaltyProgramCode;
    }
  );

  return isLoyaltyProgramSelected;
};
