import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchPackagesAvailabilitySaga } from "./fetchPackagesAvailabilitySaga";

export function* watchFetchPackagesAvailability() {
  yield takeLatest(
    actionTypes.FETCH_INITIAL_PACKAGES_AVAILABILITY,
    fetchPackagesAvailabilitySaga
  );
  yield takeLatest(
    actionTypes.FETCH_MORE_PACKAGES_AVAILABILITY,
    fetchPackagesAvailabilitySaga
  );
}
