import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";
import { PolicyModalButton, PolicyDetailsModal } from "halifax";
import { ClientContext } from "../../../../App";
import { PackagesSearchControl } from "../../../search/components/PackagesSearchControl";
import "./styles.scss";
import { AvailabilityFilter } from "../AvailabilityFilter";

export const AvailabilitySearchControl = () => {
  const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);

  const { policies } = useContext(ClientContext);

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    // trackEvent({
    //   eventName: VIEWED_POLICY_MODAL,
    //   properties: {
    //     type: POLICY_MODAL,
    //     entry_point: ModalScreens.HOTELS_AVAILABILITY,
    //     funnel: "hotels",
    //   },
    // });
  };

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  return (
    <Box className="packages-availability-search-container">
      <Box
        className={clsx("packages-availability-search-control", {
          "show-policy-button": isPolicyDescriptorsEnabled,
        })}
      >
        <Box className="search-components-section">
          <PackagesSearchControl showSearchButton={false} />
        </Box>
        <Box className="sort-filter-section">
          <AvailabilityFilter />
        </Box>
      </Box>
      {isPolicyDescriptorsEnabled && (
        <>
          <Box className="policy-modal-button-container">
            <PolicyModalButton
              underline={false}
              onClick={onShowPolicyDetailsModal}
            />
          </Box>
          <PolicyDetailsModal
            policies={policies}
            isOpen={isPolicyModalOpen}
            setIsOpen={setIsPolicyModalOpen}
            productType="hotel"
          />
        </>
      )}
    </Box>
  );
};
