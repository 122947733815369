import { Box, Link, Typography } from "@material-ui/core";
import "./styles.scss";

import React, { useRef, useEffect, useMemo, useState, useContext } from "react";
import {
  CorpLodging,
  CorpLodgingData,
  IIdLodgings,
  Lodging,
  PackageFetchDetailsEnum,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { AvailabilityListConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
// import dayjs from "dayjs";
import {
  CorpCompareBarBanner,
  CorpCompareBarTooltip,
  CorpPolicyBanner,
  GenericShopListFooter,
  HotelAvailabilityCard,
  Icon,
  IconName,
  OutOfPolicyModal,
  useDeviceTypes,
} from "halifax";
import * as textConstants from "./textConstants";
import ReactList from "react-list";
import clsx from "clsx";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";
import { AvailabilitySearchControl } from "../AvailabilitySearchControl";
import { PackagesAvailabilityCallState } from "../../reducer/state";
import { AvailabilitySort } from "../AvailabilitySort";
import { AvailabilityNoResults } from "../AvailabilityNoResults";
import { PATH_HOME } from "../../../../utils/paths";
import { setOpenDatesModal } from "../../actions/actions";
import { ClientContext } from "../../../../App";
import {
  useExperiments,
  getExperimentVariant,
  AVAILABLE,
  CORP_DEBUGGING_PANEL,
} from "../../../../context/experiments";
import { useExperimentIsVariant } from "@capone/experiments";
import { CorporateDebuggingPanel } from "../CorporateDebuggingPanel";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import { InfoOutlined } from "@material-ui/icons";
import { RecommendedFlightsCard } from "../RecommendedFlightsCard";

const APPROXIMATE_HOTEL_AVAILABILITY_CARD_HEIGHT = 284;
export interface IAvailabilityListProps
  extends AvailabilityListConnectorProps,
    RouteComponentProps {}

export const AvailabilityList = (props: IAvailabilityListProps) => {
  const {
    nightCount,
    accountReferenceId,
    largestValueAccount,
    lodgings,
    packagesAvailabilityCallState,
    fetchMorePackagesAvailability,
    history,
    packagesByLodgingId,
    searchedLocation,
    travelersCount,
    isFilteredHotelAvailabilityLodgingsEmpty,
    recommendedFlights,
  } = props;
  // const [untilProp, setUntilProp] = useState<Date | null>(null);
  // const [fromProp, setFromProp] = useState<Date | null>(null);
  // const [refetchFromCalendarModal, setRefetchFromCalendarModal] =
  //   useState(false);
  const [locationName, setLocationName] = React.useState("");
  const [isSearchTermLodging, setIsSearchTermLodging] = React.useState(false);
  // If search term is location (ex: Toronto) vs if search term is point of interest (ex: Toronto Zoo)
  const [isSearchTermPoint, setIsSearchTermPoint] = React.useState(false);
  const listRef = useRef<ReactList | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [showPolicyModal, setShowPolicyModal] = useState<string | null>(null);
  const [corpPolicyDescriptorViewCount, setCorpPolicyDescriptorViewCount] =
    useState(0);

  const outOfPolicyCopy = isAutoApprovalEnabled
    ? "If you wish to proceed with your selection, admins will be notified upon booking that this hotel was out of policy."
    : "This hotel rate is out of your company policy. You can continue anyway or change your selection.";

  const expState = useExperiments();

  const isCorporateDebuggingPanelEnabled =
    getExperimentVariant(expState.experiments, CORP_DEBUGGING_PANEL) ===
    AVAILABLE;

  const isCorpCompareBarEnabled = useExperimentIsVariant(
    "corp-compare-to-bar",
    "variant-a"
  );

  React.useEffect(() => {
    const [locationName] = searchedLocation?.label
      ? searchedLocation.label.split(",")
      : [];
    setLocationName(locationName);

    const placeTypes = searchedLocation
      ? (searchedLocation.id as IIdLodgings).lodgingSelection.placeTypes
      : [];

    setIsSearchTermLodging(placeTypes.includes("lodging"));
    setIsSearchTermPoint(
      !placeTypes.includes("locality") &&
        !placeTypes.includes("political") &&
        !placeTypes.includes("country")
    );
  }, [searchedLocation]);

  useEffect(() => {
    if (
      packagesAvailabilityCallState ===
        PackagesAvailabilityCallState.FollowUpSearchCallSuccess ||
      packagesAvailabilityCallState ===
        PackagesAvailabilityCallState.InitialSearchCallSuccess
    ) {
      setTimeout(() => fetchMorePackagesAvailability(history), 100);
    }
  }, [packagesAvailabilityCallState, fetchMorePackagesAvailability, history]);

  const sortedLodgings = useMemo(() => {
    // SORT
    const searchedLodgingSelection = (searchedLocation?.id as IIdLodgings)
      ?.lodgingSelection;

    if (
      searchedLodgingSelection &&
      searchedLodgingSelection.placeTypes.includes("lodging")
    ) {
      const searchTermName = searchedLodgingSelection.searchTerm.split(",")[0];
      return lodgings
        .slice()
        .sort(
          (a, b) =>
            b.lodging.name.toLowerCase().indexOf(searchTermName.toLowerCase()) -
            a.lodging.name.toLowerCase().indexOf(searchTermName.toLowerCase())
        );
    }
    return lodgings;
  }, [lodgings, searchedLocation]);

  const renderEachLodging = (
    lodgingData: Lodging | CorpLodging,
    index: number
  ) => {
    const {
      isFreeCancel,
      lodging,
      bestPromotionThisLodging,
      // bestOfferThisLodging,
    } = lodgingData;
    const available =
      typeof lodgingData.available === "undefined"
        ? true
        : lodgingData.available;

    const hasPolicyCompliance = "corporateTravel" in lodging;
    const isInPolicy =
      hasPolicyCompliance &&
      (lodging.corporateTravel.policyCompliance.isInPolicy ?? true);
    // const policyReasons = hasPolicyCompliance
    //   ? lodging.corporateTravel.policyCompliance.reasons
    //   : [];

    const onOpenPolicyDescriptor = () => {
      if (corpPolicyDescriptorViewCount <= 5) {
        // trackEvent({
        //   eventName: VIEWED_POLICY_DESCRIPTOR,
        //   properties: {
        //     type: POLICY_DESCRIPTOR,
        //     entry_point: PolicyDescriptorEntryPoints.HOTELS_LIST,
        //     funnel: "hotels",
        //     policy_reason: policyReasons.join(", "),
        //   },
        // });
        setCorpPolicyDescriptorViewCount((prevState) => prevState + 1);
      }
    };
    const showCorpCompareBarBanner =
      matchesDesktop &&
      isCorpCompareBarEnabled &&
      Boolean(lodgingData.loyaltyProgramCode);

    return (
      <Link
        id={`availability-row-${index}`}
        className={clsx("availability-row", {
          unavailable: !available,
          "nav-improvement": true,
        })}
        component="button"
        onClick={() => {
          console.log("in here");
        }}
      >
        <Box
          className={clsx("hotel-availability-card-wrapper", {
            unavailable: !available,
            // preferred: shouldMerchandisePreferred(lodgingData),
            // "on-sale": showOnSaleBanner,
            "capone-corporate": isCorpTenant(config.TENANT),
            "in-policy": isInPolicy,
          })}
        >
          <CorpPolicyBanner
            variant={matchesDesktop ? "descriptor" : "base"}
            productType="hotel"
            corporateTravel={(lodging as CorpLodgingData)?.corporateTravel}
            limit={policies?.hotels.policies[0].maxPricePerNight}
            onOpen={() => onOpenPolicyDescriptor()}
            {...(matchesMobile && { borderRadius: 0 })}
          />
          <CorpCompareBarBanner
            show={showCorpCompareBarBanner}
            onOpen={onOpenCompareBarTooltip(
              ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_CARD
            )}
          />
          <Box className="hotel-availability-card-content">
            <HotelAvailabilityCard
              hotelAvailabilityInfo={{
                bestPromotionThisLodging,
                lodging,
                price: available
                  ? packagesByLodgingId?.[lodging.id]?.packageDetails.pricing
                  : undefined,
                nightCount,
                // TODO: ask about where to get city name only (e.g.: Toronto)
                neighbourhood: searchedLocation?.label,
                rewardsKey: accountReferenceId || undefined,
                available: lodgingData.available,
                bestOfferThisLodging: lodgingData.bestOfferThisLodging,
                searchedRoomCount: 1,
              }}
              earnTagContent={
                <>
                  <Icon name={IconName.StarIcon} />
                  <Typography
                    className="earn-tag-text"
                    dangerouslySetInnerHTML={{
                      __html: textConstants.getEarnTagText(
                        largestValueAccount.earn.hotelsMultiplier,
                        largestValueAccount.rewardsBalance
                          .currencyDescription ??
                          largestValueAccount.rewardsBalance.currency
                      ),
                    }}
                  />
                </>
              }
              earnTagClassName="b2b"
              isGlobalMobileNavExperiment
              variant={"package"}
              travelersCount={travelersCount}
              isFreeCancel={isFreeCancel}
              isFreeBreakfastCancelExperiment
              isMobile={matchesMobile}
            />
          </Box>
        </Box>
      </Link>
    );
  };

  const containerRef = useRef<null | HTMLElement>(null);

  return (
    <Box
      className={clsx("availability-list-root", {
        mobile: matchesMobile,
      })}
      {...{ ref: containerRef }}
    >
      {isCorporateDebuggingPanelEnabled && (
        <CorporateDebuggingPanel lodgings={lodgings} />
      )}
      {showPolicyModal && (
        <OutOfPolicyModal
          subtitle={outOfPolicyCopy}
          isOpen={!!showPolicyModal}
          isMobile={matchesMobile}
          onClose={() => {
            setShowPolicyModal(null);
            // trackEvent(
            //   getClickCancelOOPModalEvent(
            //     ModalScreens.HOTELS_AVAILABILITY,
            //     "hotels",
            //     modalType
            //   )
            // );
          }}
          onContinue={() => {
            window.open(showPolicyModal, "_blank");
            setShowPolicyModal(null);
            // trackEvent(
            //   getClickContinueOOPModalEvent(
            //     ModalScreens.HOTELS_AVAILABILITY,
            //     "hotels",
            //     modalType
            //   )
            // );
          }}
        />
      )}
      <Box className="availability-list-container">
        {!matchesMobile && <AvailabilitySearchControl />}
        {isCorpCompareBarEnabled && (
          <Box
            className={clsx("availability-list-corp-bar-banner", {
              mobile: matchesMobile,
            })}
          >
            <Icon name={IconName.TagIcon} />
            <CorpCompareBarTooltip
              label={
                <Typography>
                  {textConstants.CORP_COMPARE_TO_BAR_BANNER_TEXT}
                  {matchesMobile && <InfoOutlined fontSize="small" />}
                </Typography>
              }
              additionalTooltipContent={
                <Typography style={{ fontStyle: "italic", marginTop: "10px" }}>
                  Look for the price tag icon to find hotels with these rates!
                </Typography>
              }
              onOpen={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_HEADER
              )}
              hideIcon={matchesMobile}
            />
          </Box>
        )}
        {recommendedFlights?.type === PackageFetchDetailsEnum.Flight && (
          <>
            <Typography className="recommended-flights-header">
              {textConstants.RECOMMENDED_FLIGHTS_HEADER}
            </Typography>
            <RecommendedFlightsCard isMobile={matchesMobile} />
          </>
        )}
        {!matchesMobile && (
          <Box className="availability-list-heading-sort">
            {locationName ? (
              <Typography
                variant="h3"
                className="availability-list-count-heading"
              >
                {textConstants.HOTEL_COUNT_HEADING_TEXT(
                  lodgings.filter(
                    (lodging) => lodging.available || !("available" in lodging)
                  ).length,
                  locationName,
                  isSearchTermLodging,
                  isSearchTermPoint
                )}
              </Typography>
            ) : (
              <div />
            )}
            <AvailabilitySort />
          </Box>
        )}

        {isFilteredHotelAvailabilityLodgingsEmpty ? (
          <Box className="no-hotels-found-components-section">
            <AvailabilityNoResults />
          </Box>
        ) : (
          <div ref={divRef} className="availability-list">
            <ReactList
              ref={listRef}
              itemRenderer={(index: number) => {
                if (
                  packagesAvailabilityCallState ===
                  PackagesAvailabilityCallState.InitialMapSearchCallInProcess
                ) {
                  return (
                    <HotelAvailabilitySkeleton
                      key={index}
                      isMobile={matchesMobile}
                    />
                  );
                } else if (index < sortedLodgings.length) {
                  // [Packages TO-DO]: Update to render correct components based on callstate
                  return (
                    <React.Fragment key={index}>
                      {renderEachLodging(lodgings[index], index)}
                    </React.Fragment>
                  );
                }
                // render CardSkeleton when it's not Complete
                else if (
                  packagesAvailabilityCallState !==
                  PackagesAvailabilityCallState.Complete
                ) {
                  return (
                    <HotelAvailabilitySkeleton
                      key={index}
                      isMobile={matchesMobile}
                    />
                  );
                }
                // render MoreResults footer when it's Complete
                else if (
                  packagesAvailabilityCallState ===
                  PackagesAvailabilityCallState.Complete
                ) {
                  return (
                    <>
                      {/* {matchesMobile ? (
                        <MobilePopoverCard
                          open={openDatesModal}
                          onClose={() => setOpenDatesModal(false)}
                          fullScreen={true}
                          className="mobile-dates-selection-root"
                          contentClassName="mobile-dates-selection-content-wrapper"
                          topRightButton={
                            <ActionLink
                              className="dates-selection-close-button"
                              content={<CloseButtonIcon />}
                              label="Close"
                              onClick={() => setOpenDatesModal(false)}
                            />
                          }
                        >
                          <Box className="mobile-dates-selection-content-container">
                            <MobileCalendarPicker
                              onComplete={() => {
                                setOpenDatesModal(false);
                              }}
                            />
                          </Box>
                        </MobilePopoverCard>
                      ) : (
                        <DesktopCalendarPicker
                          open={openDatesModal}
                          closePopup={() => {
                            setOpenDatesModal(false);
                          }}
                          fromProp={fromProp}
                          untilProp={untilProp}
                          onClickDone={() => {
                            setRefetchFromCalendarModal &&
                              setRefetchFromCalendarModal(true);
                          }}
                        />
                      )} */}
                      <GenericShopListFooter
                        className={clsx(
                          "availability-list-find-more-results",
                          "b2b"
                        )}
                        title={textConstants.FIND_MORE_RESULTS_TITLE_TEXT}
                        subtitle={textConstants.FIND_MORE_RESULTS_SUBTITLE_TEXT}
                        buttons={[
                          {
                            title: textConstants.ADJUST_DATES_TEXT,
                            className: clsx(
                              "find-more-results-button",
                              "adjust-dates",
                              "b2b"
                            ),
                            isPrimary: true,
                            onClick: () => setOpenDatesModal(true),
                          },
                          {
                            title: textConstants.SEARCH_AGAIN_TEXT,
                            className: clsx(
                              "find-more-results-button",
                              "search-again",
                              "b2b"
                            ),
                            isPrimary: false,
                            onClick: () => history.push(PATH_HOME),
                          },
                        ]}
                        isMobile={matchesMobile}
                      />
                    </>
                  );
                }

                return <></>;
              }}
              length={lodgings.length + 1}
              type="variable"
              itemSizeEstimator={() =>
                APPROXIMATE_HOTEL_AVAILABILITY_CARD_HEIGHT
              }
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

interface IHotelAvailabilitySkeletonProps {
  isMobile: boolean;
}

const HotelAvailabilitySkeleton = (props: IHotelAvailabilitySkeletonProps) => {
  const { isMobile } = props;

  return (
    <Box className="availability-skeleton-row">
      <Box className="hotel-availability-card-wrapper">
        <HotelAvailabilityCard isSkeleton={true} isMobile={isMobile} />
      </Box>
    </Box>
  );
};
